<template>
	<div class="main-container">
		<!-- <h5 class=" col-lg-12 text-orange pt-2 pt-lg-0" @click="toggleAfterSchedule()">
			After Hours Schedule
			<span v-if="displayAfterHourSchedule"><i class="fa-solid fa-caret-down" style="color: #f48062"></i></span>
			<span v-else><i class="fa-solid fa-caret-right" style="color: #f48062"></i></span>
		</h5>
		<div class="col-lg-12 " v-show="displayAfterHourSchedule" role="alert">
			<table class="table w-90 table-borderless">
				<tbody>
					<tr>
						<td v-for="(accordian, accordKey) in after_hour_schedule" :key="accordKey">
							<p class="day-header">{{ accordian.day }}</p>
							<p class="day-time">{{ accordian.closed_from }} - {{ accordian.closed_to }}</p>
						</td>
					</tr>
				</tbody>
			</table>
		</div> -->
		<div v-show="displayMessage">
			<!-- <p class="regular-p mb-2 text-center after-hour-message">{{ messageContent }}</p>  -->
			<p class="after-hour-message">Shop our in-house by-the-bunch inventory!</p>
			<div v-show="displayTimer" class="w-50 mx-auto">
				<div class="d-flex justify-content-center">
					<div class="px-3">
						<div class="after-hour-container rounded">{{ timerDays }}</div>
						<div class="after-hour-label">DAYS</div>
					</div>
					<div class="timer-colon">:</div>
					<div class="px-3">
						<div class="after-hour-container rounded">{{ timerHours }}</div>
						<span class="after-hour-label">HOURS</span>
					</div>
					<div class="timer-colon">:</div>
					<div class="px-3">
						<div class="after-hour-container rounded">{{ timerMinutes }}</div>
						<span class="after-hour-label">MINUTES</span>
					</div>
					<!-- <div class="timer-colon">:</div>
					<div class="px-3">
						<div class="after-hour-container rounded">{{ timerSeconds }}</div>
						<span class="after-hour-label">SECONDS</span>
					</div> -->
				</div>
			</div>
		</div>
		<div v-if="displayRefreshButton" class="text-center">
			<input type="button" class="btn btn-primary" @click="refreshPage" value="Refresh" />
		</div>
	</div>
</template>
<script>
import axios from "axios";
export default {
	name: "AfterHoursCounter",
	props: {
		programId: {
			type: Number,
		}
	},
	mounted() {
		this.getProgramDetails();
	},
	data() {
		return {
			timeLeft: 0,
			secondsLeft: 0,
			displayRefreshButton: false,
			currentAccordion: "",
			displayMessage: false,
			displayTimer: false,
			displayAfterHourSchedule: false,
			after_hour_schedule: [],
			messageContent: '',
		};
	},
	computed: {
		timerDays() {
			let days = Math.floor(this.timeLeft / 86400);
			return `${days}`;
		},
		timerHours() {
			let days = Math.floor(this.timeLeft / 86400);
			let hours = Math.floor(this.timeLeft / 3600)
			if (days > 0) {
				hours = Math.floor((this.timeLeft - (days * 86400)) / 3600);
			}
			return `${hours}`;
		},
		timerMinutes() {
			let hours = Math.floor(this.timeLeft / 3600)
			const minutes = Math.floor((this.timeLeft - hours * 3600) / 60).toString();
			return `${minutes}`;
		},
		// timerSeconds() {
		// 	const seconds = Math.floor(this.timeLeft % 60).toString().padStart(2, "0");
		// 	return `${seconds}`;
		// },
	},
	methods: {
		toggleAfterSchedule() {
			this.displayAfterHourSchedule = !this.displayAfterHourSchedule;
		},
		getProgramDetails() {
			axios
				.post(
					process.env.VUE_APP_API_BASE + "auth/getProgramAfterHourSchedule",
					{
						program_id: this.programId
					},
					{
						headers: {
							"Content-type": "application/json",
							Authorization: "Bearer " + localStorage.getItem("token"),
						},
					}
				).then((response) => {
					this.secondsLeft = response.data.seconds_left;
					this.timeLeft = response.data.seconds_left
					this.messageContent = response.data.message
					this.displayMessage = false;
					console.log('BEFORE IF')
					if (this.messageContent != '') {
						this.displayMessage = true;
						console.log('IF')
					}
					if (this.timeLeft > 0)
						this.countDownTimer()
				});
		},
		countDownTimer() {
			this.displayTimer = false;
			if (this.timeLeft) {
				this.displayTimer = true;
				setTimeout(() => {
					--this.timeLeft
					this.countDownTimer()
				}, 1000);
			} else {
				this.displayRefreshButton = true;
			}
		},

		refreshPage() {
			window.location.reload()
		},
	},
};
</script>
<style scoped>
p.day-header {
	color: #000;
	background-color: #ccc;
	vertical-align: middle;
	text-transform: capitalize;
	font-weight: bold;
	padding: .5rem;
}

p.day-time {
	color: #000;
	vertical-align: middle;
	padding: .5rem;
}

.text-orange {
	color: #f48062 !important;
}

.table-borderless {
	border: 1px solid #ccc
}

.table-borderless td {
	padding: 0px !important;
}
</style>